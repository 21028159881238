import React from "react"
import Article from "../components/Article"
import i18nHOC from "../components/i18nHOC"
import Paragraph from "../components/Paragraph"
import ImageWithTitle from "../components/ImageWithTitle"
import { graphql } from "gatsby"

function mulberry({ data, t }) {
  return <Article coverImage={{ image: data.coverImage, title: t("items.mulberry.coverCap")}}
                  title={t("items.mulberry.title")}>
    <Paragraph>{t("items.mulberry.p1")}</Paragraph>
    <ImageWithTitle fluid={data.mulberry1.childImageSharp.fluid} title={t("items.mulberry.cap1")}/>
    <Paragraph>{t("items.mulberry.p2")}</Paragraph>
    <ImageWithTitle fluid={data.mulberry2.childImageSharp.fluid} title={t("items.mulberry.cap2")}/>
  </Article>
}

export default i18nHOC(mulberry);

export const query = graphql`
  query {
    coverImage: file(relativePath: { eq: "mulberry/DSC_2590.JPG" }) {
        ...CoverImage
    },
    mulberry1: file(relativePath: { eq: "mulberry/IMG_7856.jpg" }) {
      ...FluidArticleImage
    },
    mulberry2: file(relativePath: { eq: "mulberry/IMG_9849 (1).jpg" }) {
      ...FluidArticleImage
    },
  }
`